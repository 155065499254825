import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SignIn } from "./components/SignIn";
import { makeStyles } from "@mui/styles";
import { Logout } from "@mui/icons-material";
import { COLOR_BRAND, IMAGES_ENDPOINT } from "./consts";

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [customer, setCustomer] = useState({
    name: "Authenticating",
    color: "#000000",
    name: "Authenticating",
    username: "...",
    redirect: '?customer=8&user=35&is_admin=1'
  });
  const [redirectUrl, setRedirectUrl] = useState("");
  const classes = useStyles();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    init();

    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const init = async () => {
    const isSignedIn = localStorage.getItem('signedIn');
    console.info("isSignedIn: "+ isSignedIn);
    if(isSignedIn){
      const userData = localStorage.getItem('userData');
      setCustomer(JSON.parse(userData));
      setIsAuthenticated(true);
    }
  }

  const signOut = async () => {
    setIsAuthenticated(false);
    setCustomer({});
    window.location.href = "/";
    localStorage.removeItem('signedIn');
  }

  const saveCustomerDetails = (data) => {
    setCustomer(data);
    localStorage.setItem('userData', JSON.stringify(data));
  }

  function getFontColor(backgroundColor) {
    // Convert hex to RGB
    const rgb = parseInt(backgroundColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
  
    // Calculate luminance
    const rNorm = r / 255, gNorm = g / 255, bNorm = b / 255;
    const luminance = 0.2126 * rNorm + 0.7152 * gNorm + 0.0722 * bNorm;
  
    // Determine contrast
    return luminance > 0.5 ? "#000000" : "#ffffff";
  }
  
  return (
    <Router>
      {isAuthenticated ? (
        <>
          <div className={classes.header} style={{ backgroundColor: isAuthenticated ? customer.color : COLOR_BRAND }}>
            <div className={classes.logoContainer}>
              <img src={IMAGES_ENDPOINT+"logo.png"} className={classes.logo}/>
            </div>
            <div style={{ display: "flex", width: "100%", flex: 1, paddingLeft: 20, flexDirection: "row" }}>
              {isAuthenticated && (
                <>
                  <div className={classes.app_name} style={{ color: getFontColor(customer.color) }}>{customer.name} &nbsp;<b style={{ fontSize: "9px" }}>({customer.username})</b></div>
                  <Logout className={classes.logout} style={{ color: getFontColor(customer.color) }} onClick={() => { signOut(); }} />
                </>
              )}
            </div>
          </div>
          <iframe className={classes.root} style={{ height: dimensions.height }} src={customer.redirect} />
        </>
      ) : (
        <Routes>
          <Route path="" element={<SignIn setIsAuthenticated={setIsAuthenticated} saveCustomerDetails={saveCustomerDetails} />} />
        </Routes>
      )}
    </Router>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    width: "100%",
    height: 40,
    alignItems: "center",
    position: "fixed"
  },
  headerTopPane: {
    width: '100%',
    height: 100,
    position: 'absolute',
    top: -100
  },
  root: {
    display: "block",
    flex: 1,
    width: "100%",
    height: "100%",
    borderWidth: 0
  },
  logoContainer: {
    display: "flex", 
    backgroundColor: "#ffffff",
    width: 160, 
    height: "100%", 
    position: "relative",
    alignItems: "center",
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  logo: {
    height: 30,
    width: "auto",
    marginLeft: 20
  },
  app_name: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 12
  },
  logout: {
    position: "absolute",
    right: 20,
    cursor: "pointer"
  }
}));